import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ethers } from "ethers";

import { backendApiUrl } from "../api/utils";

export const poolsApi = createApi({
  reducerPath: "poolsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: backendApiUrl,
  }),
  tagTypes: [
    "Pool",
    "Pools",
    "Posts",
    "UserPoints",
    "Campaign",
    "Notifications",
  ],
  endpoints: (builder) => ({
    getPoolsData: builder.query({
      providesTags: ["Pools"],
      query: (args) => {
        if (args) {
          const { sortBy, sortIn, page = 1, limit = 20 } = args;
          return {
            url: "/pool/all",
            params: {
              sort_by: sortBy,
              sort_in: sortIn,
              limit: limit,
              page: page,
            },
          };
        } else {
          return {
            url: "/pool/all",
            params: {
              limit: 300,
            },
          };
        }
      },
      transformResponse: (response) => {
        let result = response.data.map((p) => {
          const creatorEth = ethers.getBigInt(p.SponsorBalance);
          const followerEth = ethers.getBigInt(p.TicketBalance);
          const totalEth = creatorEth + followerEth;
          const followerApr = followerEth
            ? (ethers.getBigInt(4) * totalEth) / followerEth
            : 0;
          const poolSettings = JSON.parse(p.PoolSettings);
          const poolInfo = {
            id: p.ID,
            userName: p.User.name,
            userProfileUrl: p.User.Picture,
            userAddress: p.User.ethereum_addr,
            description: p.Description,
            createdAt: p.CreatedAt,
            creatorEth: ethers.formatEther(creatorEth).substring(0, 7),
            followerEth: ethers.formatEther(totalEth).substring(0, 7),
            memberCount: p.MemberCount,
            totalEth: ethers.formatEther(totalEth).substring(0, 7),
            followerApr: followerApr.toString(),
            isHidePrize: poolSettings?.fee == 100,
          };
          return {
            ...p,
            poolInfo,
            poolSettings,
          };
        });
        return { data: result, pagination: response.pagination };
      },
    }),
    getSinglePoolData: builder.query({
      providesTags: (_result, _error, arg) => [{ type: "Pool", id: arg }],
      query: (poolId) => {
        return {
          url: `/pool/${poolId}`,
        };
      },
      transformResponse: (response) => {
        const creatorEth = ethers.getBigInt(response.data.pool.SponsorBalance);
        const followerEth = ethers.getBigInt(response.data.pool.TicketBalance);
        const totalEth = creatorEth + followerEth;
        const followerApr = followerEth
          ? (ethers.getBigInt(4) * totalEth) / followerEth
          : 0;
        const poolSettings = JSON.parse(response.data.pool.PoolSettings);
        const poolInfo = {
          id: response.data.pool.ID,
          userName: response.data.pool.User.name,
          userProfileUrl: response.data.pool.User.Picture,
          userAddress: response.data.pool.User.ethereum_addr,
          description: response.data.pool.Description,
          createdAt: response.data.pool.CreatedAt,
          creatorEth: ethers.formatEther(creatorEth).substring(0, 7),
          followerEth: ethers.formatEther(totalEth).substring(0, 7),
          memberCount: response.data.pool.MemberCount,
          totalEth: ethers.formatEther(totalEth).substring(0, 7),
          followerApr: followerApr.toString(),
          isHidePrize: poolSettings?.fee == 100,
        };
        return {
          ...response.data.pool,
          poolInfo,
          poolSettings,
          freeQuota: {
            points: response.data?.free_quota?.Points,
            pointsSpent: response.data?.free_quota?.PointSpent,
          },
        };
      },
    }),
    getPoolPosts: builder.query({
      providesTags: (_result, _error, arg) => [
        { type: "Pool", id: arg.poolId },
        { type: "Posts", id: arg.poolId },
      ],
      query: ({ poolId, signature, page, limit }) => {
        return {
          url: `/social_post/pools/${poolId}`,
          params: { page, limit },
          headers: {
            authorization: signature,
          },
        };
      },
      transformResponse: (response) => response,
    }),
    getPostReplyPosts: builder.query({
      providesTags: (_result, _error, arg) => [
        { type: "Posts", id: arg.parentCastId },
      ],
      query: ({ parentCastId, signature, page, limit = 50 }) => {
        return {
          url: `/social_post/parent_cast/${parentCastId}`,
          params: { page, limit },
          headers: {
            authorization: signature,
          },
        };
      },
      transformResponse: (response) => response,
    }),
    submitPost: builder.mutation({
      invalidatesTags: (result, _error, arg) => {
        const tags = [
          {
            type: "Posts",
            id: arg.parentId,
          },
        ];
        if (result?.data?.Tipping) {
          tags.push("UserPoints");
          tags.push({ type: "Pool", id: result?.data?.poolId });
        }
        return tags;
      },
      query: ({ content, signature }) => ({
        url: "/social_post/cast/post",
        method: "POST",
        headers: {
          authorization: signature,
          "content-type": "application/json",
        },
        body: content,
      }),
    }),
    getDepositedPools: builder.query({
      providesTags: ["Pools"],
      query: ({ address, page, limit }) => {
        return {
          url: `/pool/deposited/list/${address}`,
          params: { page, limit, self_exclude: true },
        };
      },
      transformResponse: (response) => {
        let result = response.data.pools.map((p) => {
          const creatorEth = ethers.getBigInt(p.SponsorBalance);
          const followerEth = ethers.getBigInt(p.TicketBalance);
          const totalEth = creatorEth + followerEth;
          const followerApr = followerEth
            ? (ethers.getBigInt(4) * totalEth) / followerEth
            : 0;
          const participant = response.data?.pool_participants?.find(
            (parti) => parti.PoolId === p.ID,
          );
          if (participant) {
            p.SponsorBalance = ethers.formatEther(participant.SponsorBalance);
            p.TicketBalance = ethers.formatEther(participant.TicketBalance);
          }

          const poolInfo = {
            id: p.ID,
            userName: p.User.name,
            userProfileUrl: p.User.Picture,
            userAddress: p.User.ethereum_addr,
            description: p.Description,
            createdAt: p.CreatedAt,
            creatorEth: ethers.formatEther(creatorEth).substring(0, 7),
            followerEth: ethers.formatEther(totalEth).substring(0, 7),
            memberCount: p.MemberCount,
            totalEth: ethers.formatEther(totalEth).substring(0, 7),
            followerApr: followerApr.toString(),
          };
          const poolSettings = JSON.parse(p.PoolSettings);
          return {
            ...p,
            poolInfo,
            poolSettings,
          };
        });
        return {
          data: result,
          depositedPoolIds: response.data.pool_participants.map(
            (p) => p.PoolId,
          ),
          pagination: response.pagination,
        };
      },
    }),
    getActivities: builder.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { page = 1, limit = 300 } = arg;
        try {
          // query logs
          const logs = await baseQuery(
            `/activities/logs?limit=${limit}&page=${page}`,
          );
          let events = [];
          let unknowUsers = new Set();
          logs.data.data.activities.map((activity) => {
            if (activity.RawData.length > 0) {
              activity.RawData.map((raw) => {
                // filter creator fee event
                if (activity.Pool.User.ethereum_addr != raw.Winner) {
                  events.push({
                    ...activity,
                    RawData: raw,
                  });
                  unknowUsers.add(raw.Winner);
                }
              });
            } else {
              events.push(activity);
            }
          });
          // query unknown users
          if (unknowUsers.size > 0) {
            let queryArray = Array.from(unknowUsers);
            const users = await baseQuery(
              `/lookup/get_users?ethereum_address=${queryArray.toString()}`,
            );
            let userMapping = {};
            users.data.data.users.map((user) => {
              userMapping[user.ethereum_addr] = {
                name: user.name,
                picture: user.Picture,
              };
            });
            events.map((e, i) => {
              if (e.FromTable == "prize_pool_awarded_events") {
                events[i].RawData.name = userMapping[e.RawData.Winner].name;
                events[i].RawData.picture =
                  userMapping[e.RawData.Winner].picture;
              }
            });
          }
          return {
            data: { events, pagination: logs.data.pagination },
          };
        } catch (e) {
          return {
            error: {
              data: "Something went wrong",
            },
          };
        }
      },
    }),
    getUserLatestDepositTime: builder.query({
      providesTags: (_result, _error, arg) => [
        { type: "Pool", id: arg.poolId },
      ],
      query: ({ address, poolId }) => {
        return {
          url: `/events/pools/deposit`,
          params: { operator: address, pool_id: poolId },
        };
      },
      transformResponse: (response) => {
        if (response?.data?.records.length) {
          return response.data.records[0].BlockTime;
        }
        return 0;
      },
    }),
    getDrawActivities: builder.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const logs = await baseQuery(
            `/events/pools/awarded?pool_id=${arg.poolId}&limit=50`,
          );
          let events = [];
          let myRecords = [];
          let unknowUsers = new Set();
          logs.data.data.records.map((record) => {
            if (record.Winner == arg.poolOwnerAddress) return;
            if (
              events.length > 0 &&
              events[events.length - 1].tx == record.Tx
            ) {
              events[events.length - 1].winners.push(record.Winner);
            } else {
              events.push({
                prize: ethers.formatEther(`${record.Amount}`),
                tx: record.Tx,
                drawRound: events.length,
                winners: [record.Winner],
              });
            }
            unknowUsers.add(record.Winner);
          });
          // query unknown users
          if (unknowUsers.size > 0) {
            let queryArray = Array.from(unknowUsers);
            const users = await baseQuery(
              `/lookup/get_users?ethereum_address=${queryArray.toString()}`,
            );
            let userMapping = {};
            users.data.data.users.map((user) => {
              userMapping[user.ethereum_addr] = user.name;
            });
            events.map((e, i) => {
              e.winners = e.winners.map((winner) => {
                if (winner == arg.userAddress) {
                  myRecords.push({
                    drawRound: i,
                    amount: e.prize,
                    tx: e.tx,
                  });
                }
                return userMapping[winner];
              });
            });
          }
          return {
            data: {
              myRewardRecords: myRecords,
              draws: events,
            },
          };
        } catch (e) {
          return {
            error: {
              data: "Something went wrong",
            },
          };
        }
      },
    }),
    getUniversalPoolData: builder.query({
      query: () => `pools/info`,
      transformResponse: (response) => {
        if (response?.data) {
          let totalBalance = ethers.toBigInt(
            response?.data.total_staked_balance,
          );
          let totalReserve = ethers.toBigInt(
            response?.data.total_reserve_supply,
          );
          let reserveAcceleration =
            totalBalance / 20n / 25n / 365n / 24n / 60n / 60n;
          return {
            reserve: totalReserve,
            reserveAcceleration: reserveAcceleration,
          };
        }
        return {
          reserve: 0n,
          reserveAcceleration: 0n,
        };
      },
    }),
    getPoolFollowers: builder.query({
      providesTags: (_result, _error, arg) => [
        { type: "Pool", id: arg.poolId },
      ],
      query: ({ poolId, page = 1, limit = 20 }) => {
        return {
          url: `/pool/${poolId}/member`,
          params: { sort_by: "ticket_balance", page, limit },
        };
      },
      transformResponse: (response) => {
        response.data?.member?.forEach((m) => {
          m.twitterProfile = m.SocialProfiles.find((item) => item.Type === "x");
          m.fcProfile = m.SocialProfiles.find(
            (item) => item.Type === "warpcast",
          );
          const participant = response.data?.pool_participants?.find(
            (p) => p.UserId === m.ID,
          );
          if (participant) {
            if (participant.TicketBalance) {
              m.ticketBalance = ethers.formatEther(participant.TicketBalance);
            }
            if (participant.SponsorBalance) {
              m.sponsorBalance = ethers.formatEther(participant.SponsorBalance);
            }
          }
        });
        return response;
      },
    }),
    likePost: builder.mutation({
      invalidatesTags: (_result, _error, arg) => [
        {
          type: "Posts",
          id: arg.parentId,
        },
      ],
      query: ({ castId, signature }) => ({
        url: `/social_post/cast/${castId}/like`,
        method: "POST",
        headers: {
          authorization: signature,
          "content-type": "application/json",
        },
      }),
    }),
    getUserPoints: builder.query({
      query: (userId) => {
        return {
          url: `/points/info/${userId}`,
        };
      },
      providesTags: ["UserPoints"],
      transformResponse: (response) => {
        let creatorPoint;
        let participantPoint;
        response.point_emit_bases?.forEach((p) => {
          if (p.TypeId === 1) {
            creatorPoint = p.MultiplierValue;
          }
          if (p.TypeId === 2) {
            participantPoint = p.MultiplierValue;
          }
        });
        return {
          multiplier: { creator: creatorPoint, participant: participantPoint },
          points: response.data.Amount,
        };
      },
    }),
    getCreatorPointsRank: builder.query({
      query: (limit = 10) => {
        return {
          url: `/points/rank/creator`,
          params: { page: undefined, limit: limit },
        };
      },
      transformResponse: (response) =>
        response.data.map((u) => ({
          user: {
            name: u.User.name,
            address: u.User.ethereum_addr,
            pfp: u.User.Picture,
            poolId: u.User.Pools?.[0]?.ID,
          },
          points: u.UserPoint.Amount,
          multiplier: u.MultiplierValue,
        })),
    }),
    getParticipantPointsRank: builder.query({
      query: (limit = 10) => {
        return {
          url: `/points/rank/participant`,
          params: { page: undefined, limit: limit },
        };
      },
      transformResponse: (response) =>
        response.data.map((u) => ({
          user: {
            name: u.User.name,
            address: u.User.ethereum_addr,
            pfp: u.User.Picture,
            poolId: u.User.Pools?.[0]?.ID,
          },
          points: u.UserPoint.Amount,
          multiplier: u.MultiplierValue,
        })),
    }),
    getTotalPointsRank: builder.query({
      query: (limit = 10) => {
        return {
          url: `/points/rank/total`,
          params: { page: undefined, limit: limit },
        };
      },
      transformResponse: (response) =>
        response.data.map((u) => {
          let creatorPoint;
          let participantPoint;
          u.PointEmitBases?.forEach((p) => {
            if (p.TypeId === 1) {
              creatorPoint = p.MultiplierValue;
            }
            if (p.TypeId === 2) {
              participantPoint = p.MultiplierValue;
            }
          });
          return {
            user: {
              name: u.User.name,
              address: u.User.ethereum_addr,
              pfp: u.User.Picture,
              poolId: u.User.Pools?.[0]?.ID,
            },
            points: u.Amount,
            multiplier: {
              creator: creatorPoint,
              participant: participantPoint,
            },
          };
        }),
    }),
    depositHook: builder.mutation({
      invalidatesTags: (_result, _error, arg) => [
        { type: "Pool", id: arg.poolId },
        "Pools",
      ],
      query: ({ poolId, address, txHash }) => ({
        url: "/pool/token/deposit",
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          ethereum_addr: address,
          pool_id: poolId,
          tx: txHash,
        },
      }),
    }),
    withdrawHook: builder.mutation({
      invalidatesTags: (_result, _error, arg) => [
        { type: "Pool", id: arg.poolId },
        "Pools",
      ],
      query: ({ poolId, address, txHash }) => ({
        url: "/pool/token/withdraw",
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          ethereum_addr: address,
          pool_id: poolId,
          tx: txHash,
        },
      }),
    }),
    createPoolHook: builder.mutation({
      invalidatesTags: (_result, _error, _arg) => ["Pools"],
      query: ({ settings, signature, address, txHash, poolDesc }) => {
        let body = {
          ethereum_addr: address,
          signature: signature,
          tx: txHash,
          pool_desc: poolDesc,
        };
        if (Object.keys(settings).length > 0) {
          body.pool_settings = JSON.stringify(settings);
        }
        return {
          url: "/pool/init",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        };
      },
    }),
    getPoolsInfo: builder.query({
      query: () => ({
        url: `/pools/info`,
      }),
    }),
    getCampaign: builder.query({
      query: ({ userAddress, selectedAirdropIndex }) => ({
        url: `/campaign/${selectedAirdropIndex + 1}/check/${userAddress}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Campaign"],
    }),
    claimCampaign: builder.mutation({
      invalidatesTags: ["Campaign", "UserPoints"],
      query: ({ address, signature, selectedAirdropIndex }) => ({
        url: `/campaign/${selectedAirdropIndex + 1}/claim/${address}`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: signature,
        },
      }),
    }),
    getPointsHistory: builder.query({
      providesTags: ["UserPoints"],
      query: ({ userId, signature, page = 1, limit = 20 }) => ({
        url: `/points/${userId}/history`,
        params: { page, limit },
        headers: {
          "content-type": "application/json",
          authorization: signature,
        },
      }),
      transformResponse: (response) => {
        return { ...response, data: response.data.history };
      },
    }),
    getPointTypes: builder.query({
      keepUnusedDataFor: 3600,
      query: () => ({
        url: `/points/regular/types`,
      }),
      transformResponse: (response) => {
        const transformedData = {};
        response.data.forEach((item) => {
          transformedData[item.ID] = item.Name;
        });
        return transformedData;
      },
    }),
    getUsers: builder.query({
      query: ({ userIds }) => ({
        url: `/lookup/get_users`,
        params: { user_ids: userIds },
      }),
      transformResponse: (response) => {
        const transformedData = {};
        response.data.users.forEach((item) => {
          transformedData[item.ID] = item;
        });
        return transformedData;
      },
    }),
    getNotifications: builder.query({
      providesTags: ["Notifications"],
      query: ({ signature, page = 1, limit = 20 }) => ({
        url: `/user/notification/list`,
        params: { page, limit },
        headers: {
          "content-type": "application/json",
          authorization: signature,
        },
      }),
      transformResponse: (response) => {
        const formattedNotifications = response.data.notices.map((n) => {
          return {
            type: n.NoticeItem.EventType,
            viewed: n.Viewed,
            createdAt: n.CreatedAt,
            poolId: n.NoticeItem.ReachRoute[0],
            users: n.NoticeItem.UserProfiles,
            numbers: n.NoticeItem.Numbers[0],
            contents:
              n.NoticeItem.EventType === "replied_post"
                ? n.NoticeItem.Sections[1]
                : n.NoticeItem.Sections[0],
            parentContents:
              n.NoticeItem.EventType === "replied_post" &&
              n.NoticeItem.Sections[0],
          };
        });
        return { ...response, data: formattedNotifications };
      },
    }),
    collectNotifications: builder.mutation({
      invalidatesTags: ["Notifications"],
      query: ({ signature, createdAt }) => ({
        url: `/user/notification/collect`,
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: signature,
        },
        body: JSON.stringify({
          collect_time_before: Date.parse(createdAt),
        }),
      }),
    }),
  }),
});

export const {
  useGetPoolsDataQuery,
  useGetSinglePoolDataQuery,
  useGetPoolPostsQuery,
  useGetPostReplyPostsQuery,
  useSubmitPostMutation,
  useGetDepositedPoolsQuery,
  useGetActivitiesQuery,
  useGetUserLatestDepositTimeQuery,
  useGetDrawActivitiesQuery,
  useGetUniversalPoolDataQuery,
  useGetPoolFollowersQuery,
  useLikePostMutation,
  useGetUserPointsQuery,
  useGetCreatorPointsRankQuery,
  useGetParticipantPointsRankQuery,
  useGetTotalPointsRankQuery,
  useDepositHookMutation,
  useWithdrawHookMutation,
  useCreatePoolHookMutation,
  useGetPoolsInfoQuery,
  useGetCampaignQuery,
  useClaimCampaignMutation,
  useGetPointsHistoryQuery,
  useGetPointTypesQuery,
  useGetUsersQuery,
  useGetNotificationsQuery,
  useCollectNotificationsMutation,
} = poolsApi;

export default poolsApi;
