import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Check } from "@phosphor-icons/react";

import {
  closeNotif,
  readNotif,
  receiveNewNotif,
} from "../../redux/paramsSlice";
import {
  useCollectNotificationsMutation,
  useGetNotificationsQuery,
} from "../../redux/poolsApi";
import Paging from "../Paging/Paging";
import NotifRow from "./NotifRow";

function Notifications() {
  const dispatch = useDispatch();
  const isMainnetBannerShown = useSelector(
    (state) => state.params.isMainnetBannerShown,
  );
  const isNotifOpen = useSelector((state) => state.params.isNotifOpen);
  const hasNewNotif = useSelector((state) => state.params.hasNewNotif);
  const [collectNotification] = useCollectNotificationsMutation();
  const wallet = useSelector((state) => state.user?.wallet);
  const [page, setPage] = useState(1);

  const { data: notificationsData } = useGetNotificationsQuery(
    { signature: wallet?.signature, page, limit: 20 },
    { skip: !wallet?.signature, pollingInterval: 60000 },
  );

  useEffect(() => {
    if (notificationsData?.data?.some((n) => n.viewed === false)) {
      dispatch(receiveNewNotif());
    } else {
      dispatch(readNotif());
    }
  }, [notificationsData?.data]);

  const markAllAsRead = async () => {
    try {
      await collectNotification({
        signature: wallet?.signature,
        createdAt: notificationsData?.data[0]?.createdAt,
      }).unwrap();
      dispatch(readNotif());
    } catch (e) {
      console.log(e);
    }
  };

  if (!notificationsData) return null;

  return (
    <div
      className={`absolute left-0 top-0 z-50 h-screen w-screen bg-white/0 ${isNotifOpen ? "block" : "hidden"}`}
      onClick={() => dispatch(closeNotif())}
    >
      <div
        className={`absolute right-4 top-14 z-20 max-h-[calc(100vh-100px)] w-96 overflow-y-scroll rounded-sm border border-l border-solid border-gray-100 bg-white shadow md:right-0 md:w-full ${isMainnetBannerShown && "top-24"}`}
      >
        <div className="flex items-center justify-between border-b bg-white p-2">
          <h1 className="font-inter text-lg font-medium text-indigo-950">
            Notifications
          </h1>
          <button
            className={`flex items-center gap-1 ${hasNewNotif ? "text-indigo-600 hover:text-indigo-800" : "cursor-not-allowed text-gray-400"}`}
            disabled={!hasNewNotif}
            onClick={markAllAsRead}
          >
            <Check weight="bold" /> Mark all as read
          </button>
        </div>
        <div className="flex flex-col divide-y">
          {notificationsData.data.length === 0 && (
            <div className="flex h-10 w-full items-center justify-center text-center text-gray-500">
              No notifications
            </div>
          )}
          {notificationsData.data.map((notif, i) => (
            <NotifRow notif={notif} key={i} />
          ))}
        </div>
        <Paging
          currentPage={page}
          handleClick={(page) => setPage(page)}
          totalPages={notificationsData?.pagination?.total_pages}
        />
      </div>
    </div>
  );
}
export default Notifications;
