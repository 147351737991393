import { ethers } from "ethers";

import contents from "../utils/contents";
import { trackAmplitudeEvent } from "../utils/utils";
import { buildFetchUrl } from "./helper";
import prizePoolABI from "./prizePool.json";
import prizeStrategyABI from "./prizeStrategy.json";
import sponsorABI from "./sponsor.json";
import {
  defaultProvider,
  backendApiUrl,
  yieldGeneratePeriod,
  yieldGenerateTimestamp,
} from "./utils";

export const getVerifyMessage = async (address) => {
  const baseUrl = `${backendApiUrl}/user/verify_msg`;

  const queryParams = {
    ethereum_addr: address,
  };

  const url = buildFetchUrl(baseUrl, queryParams);

  try {
    const res = await fetch(url, {
      method: "GET",
    });
    if (res.ok) {
      const data = await res.json();
      const result = data.data;
      return result;
    } else {
      throw new Error();
    }
  } catch (error) {
    trackAmplitudeEvent(`${contents.amp.catchError} | getVerifyMessage`, {
      message: error,
    });
  }
};

export const createAccount = async (wallet) => {
  try {
    const signature = wallet.signature;
    const res = await fetch(`${backendApiUrl}/user/create`, {
      body: JSON.stringify({
        ethereum_addr: wallet.address,
        signature: signature,
      }),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    });
    if (res.ok) {
      const data = await res.json();
      const userId = data.data.ID;
      const inviteCode = data.invite_code_used[0];
      return { inviteCode, userId, signature };
    } else {
      throw new Error();
    }
  } catch (error) {
    trackAmplitudeEvent(`${contents.amp.catchError} | createAccount`, {
      message: error,
    });
  }
};

export const getTwitterLoginUrl = (address, inviteCode) => {
  let twitterUrl = `${backendApiUrl.replace("/api/v1", "")}/preauth/x?identity_coded=${address}_${inviteCode}`;
  const pathname = localStorage.getItem("pageUrl");
  twitterUrl = twitterUrl + `&over_write_pwd_redirect=${pathname}`;
  return twitterUrl;
};

export const getMyInfo = async (wallet) => {
  const data = await fetch(`${backendApiUrl}/user/me`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: wallet.signature,
    },
  });
  let result = (await data.json()).data;
  return result;
};

export const fcRegister = async (data, wallet) => {
  try {
    const res = await fetch(
      `${backendApiUrl.replace("/api/v1", "")}/auth/warpcast`,
      {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: wallet.signature,
        },
      },
    );
    if (res.ok) {
      const data = await res.json();
    } else {
      throw new Error();
    }
  } catch (e) {
    trackAmplitudeEvent(`${contents.amp.catchError} | fcRegister`, {
      message: e,
    });
    throw new Error(e.message);
  }
};

export const getTwitterRetweetUrl = (address, inviteCode) => {
  let twitterUrl = `${backendApiUrl.replace("/api/v1", "")}/preauth/x/campaign?identity_coded=${address}_${inviteCode}`;
  const pathname = window.location.href;
  twitterUrl = twitterUrl + `&over_write_pwd_redirect=${pathname}`;
  return twitterUrl;
};

export const setReferral = async (wallet, userId, referralCode) => {
  try {
    const res = await fetch(`${backendApiUrl}/user/add_referral`, {
      body: JSON.stringify({
        referral_code: referralCode,
        user_id: userId,
      }),
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: wallet.signature,
      },
    });
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      let errorMsg = await res.json();
      throw new Error(errorMsg.error);
    }
  } catch (error) {
    trackAmplitudeEvent(`${contents.amp.catchError} | addReferral`, {
      message: error,
    });
    throw new Error(error.message);
  }
};

export const completeAwardHook = async (poolId, address, txHash) => {
  const data = await fetch(`${backendApiUrl}/pool/award/hook`, {
    body: JSON.stringify({
      ethereum_addr: address,
      pool_id: poolId,
      tx: txHash,
    }),
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
  });
  let result = await data.json();
  return result.data;
};

export const getNextAwardSec = async (strategyAddress) => {
  let prizeStrategy = new ethers.Contract(
    strategyAddress,
    prizeStrategyABI,
    defaultProvider,
  );
  let nextAward = Number(await prizeStrategy.prizePeriodRemainingSeconds());
  return nextAward;
};

export const getNextYieldSec = async () => {
  // production yield generate once per day
  // develop yield generate once per hour
  // I use 4/16 todays yield timestamp as baseline to estimate future yield
  // timestamp.
  let currentTime = parseInt(new Date().getTime() / 1000);
  let remainder = (currentTime - yieldGenerateTimestamp) % yieldGeneratePeriod;
  // + 60 sec for buffer
  let diff = yieldGeneratePeriod - remainder + 60;
  return diff;
};

export const getPrize = async (poolAddress) => {
  let prizePool = new ethers.Contract(
    poolAddress,
    prizePoolABI,
    defaultProvider,
  );
  let supply = await prizePool.accountedBalance();
  let balance = await prizePool.balance();
  return ethers.formatEther(balance - supply);
};

export const checkMainnetBoredApe = async (address) => {
  const provider = new ethers.JsonRpcProvider("https://rpc.ankr.com/eth", {
    name: "Mainnet",
    chainId: 1,
  });
  // use sponsor ERC20 ABI since we only need balanceOf interface
  let nft = new ethers.Contract(
    "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    sponsorABI,
    provider,
  );
  let balance = await nft.balanceOf(address);
  return balance > 0;
};

export const updatePoolHook = async (
  pool,
  drawNumber,
  drawPeriod,
  splitFee,
  minDepositAmount,
  requireNFT,
  poolDesc,
  wallet,
) => {
  const pool_settings = JSON.parse(pool.PoolSettings);
  pool_settings.drawPeriod = drawPeriod;
  pool_settings.timelock = drawPeriod;
  pool_settings.drawNumber = drawNumber;
  pool_settings.fee = splitFee;
  pool_settings.minAmount = minDepositAmount;
  if (requireNFT) {
    pool_settings.requireNFT = true;
  }

  let body = {
    pool_desc: poolDesc,
    pool_id: pool.ID,
    pool_settings: JSON.stringify(pool_settings),
  };
  const data = await fetch(`${backendApiUrl}/pool/update`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: wallet.signature,
    },
    body: JSON.stringify(body),
  });
  let result = await data.json();
  return result;
};
