import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { imgurClientId, imgurUrl } from "../utils/utils";

export const externalApiSlice = createApi({
  reducerPath: "externalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  endpoints: (builder) => ({
    uploadToImgur: builder.mutation({
      query: (formData) => ({
        url: `${imgurUrl}/image`,
        method: "POST",
        headers: {
          Authorization: `Client-ID ${imgurClientId}`,
        },
        redirect: "follow",
        body: formData,
      }),
    }),
  }),
});

export const { useUploadToImgurMutation } = externalApiSlice;
