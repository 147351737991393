import Modal from "react-modal";
import { Provider } from "react-redux";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header/Header";
import MainnetBanner from "./components/MainnetBanner/MainnetBanner";
import LoadingModal from "./components/Modals/LoadingModal";
import Notifications from "./components/Notifications/Notifications";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";
import WithdrawPage from "./pages/Withdraw/Withdraw";
import store from "./redux/store";

Modal.setAppElement("#root");

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <div className="flex h-dvh w-screen flex-col">
          <MainnetBanner />
          {/* <Banner /> */}
          <Header />
          <Notifications />
          <div className="flex-1 overflow-y-scroll bg-gradient-to-b from-neutral-100 from-20% to-indigo-100">
            <Outlet className="" />
          </div>
        </div>
      }
      errorElement={<ErrorBoundary />}
    >
      <Route
        path="*"
        element={
          <div className="mt-6 text-center font-dec">Page not found</div>
        }
      />
      <Route path="/" loader={() => redirect("/withdraw")} />
      {/* <Route path="/pools" element={<Pools />} />
      <Route path="/pool" element={<Pool />} />
      <Route path="/activities" element={<Activities />} />
      <Route path="/airdrop" element={<Airdrop />} />
      <Route path="/points" element={<Points />} />
      <Route path="/create" element={<CreatePoolPage />} />
      <Route
        path="/profile/:userId"
        element={<Profile />}
        loader={async (params) => {
          const data = store.getState();
          if (data?.user?.wallet?.address) {
            return null;
          } else {
            return redirect("/");
          }
        }}
      /> */}
      <Route path="/withdraw" index={true} element={<WithdrawPage />} />
    </Route>,
  ),
);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <LoadingModal />
      <ToastContainer />
    </Provider>
  );
}

export default App;
