import { ethers } from "ethers";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

import { getVerifyMessage } from "./API";
import wethABI from "./weth.json";

const ENV_OPTIONS = {
  PRODUCTION: "PRODUCTION",
  DEVELOP: "DEVELOP",
  TESTNET: "TESTNET",
};

// const backendApuUrlOptions = {
//   [ENV_OPTIONS.PRODUCTION]:
//     "https://o9nves6395.execute-api.ap-northeast-1.amazonaws.com/prod/api/v1",
//   [ENV_OPTIONS.DEVELOP]: "https://tomodachi.masato25.com/prod/api/v1",
//   [ENV_OPTIONS.TESTNET]:
//     "https://ipvfn2hpll.execute-api.ap-northeast-1.amazonaws.com/prod/api/v1",
// };
const backendApuUrlOptions = {
  [ENV_OPTIONS.PRODUCTION]:
    "",
  [ENV_OPTIONS.DEVELOP]: "https://tomodachi.masato25.com/prod/api/v1",
  [ENV_OPTIONS.TESTNET]:
    "https://ipvfn2hpll.execute-api.ap-northeast-1.amazonaws.com/prod/api/v1",
};

const rpcOptions = {
  [ENV_OPTIONS.PRODUCTION]: "https://rpc.blast.io/",
  [ENV_OPTIONS.DEVELOP]: "https://sepolia.blast.io/",
  [ENV_OPTIONS.TESTNET]: "https://sepolia.blast.io/",
};

const rngHelperAddressOptions = {
  [ENV_OPTIONS.PRODUCTION]: "0x8FCF6179A89a13E06092C41b63bFe4d5dDf7274e",
  [ENV_OPTIONS.DEVELOP]: "0x614cFc0eb2f1186BE3e820b1Fc3d15C408df00E7",
  [ENV_OPTIONS.TESTNET]: "0x614cFc0eb2f1186BE3e820b1Fc3d15C408df00E7",
};

const wethAddressOptions = {
  [ENV_OPTIONS.PRODUCTION]: "0x4300000000000000000000000000000000000004",
  [ENV_OPTIONS.DEVELOP]: "0x4200000000000000000000000000000000000023",
  [ENV_OPTIONS.TESTNET]: "0x4200000000000000000000000000000000000023",
};

const builderAddressOptions = {
  [ENV_OPTIONS.PRODUCTION]: "0x5dfee62C78A0E607CCE6A5d4458c328A03275ba2",
  [ENV_OPTIONS.DEVELOP]: "0x66Ef5aF335eBada583E52A26e49d2f72589e3954",
  [ENV_OPTIONS.TESTNET]: "0x66Ef5aF335eBada583E52A26e49d2f72589e3954",
};

const rngAddressOptions = {
  [ENV_OPTIONS.PRODUCTION]: "0xD8006420c1b2901849505eb3517cc36a169AE0A7",
  [ENV_OPTIONS.DEVELOP]: "0xA579C3A2E46FDbC91dB4630903BAC783aeA12360",
  [ENV_OPTIONS.TESTNET]: "0xA579C3A2E46FDbC91dB4630903BAC783aeA12360",
};

const hexChainIdOptions = {
  [ENV_OPTIONS.PRODUCTION]: "0x13E31",
  [ENV_OPTIONS.DEVELOP]: "0xa0c71fd",
  [ENV_OPTIONS.TESTNET]: "0xa0c71fd",
};

const chainIdOptions = {
  [ENV_OPTIONS.PRODUCTION]: 81457,
  [ENV_OPTIONS.DEVELOP]: 168587773,
  [ENV_OPTIONS.TESTNET]: 168587773,
};

const chainNameOptions = {
  [ENV_OPTIONS.PRODUCTION]: "Blast",
  [ENV_OPTIONS.DEVELOP]: "Blast Sepolia",
  [ENV_OPTIONS.TESTNET]: "Blast Sepolia",
};

const yieldGeneratePeriodOptions = {
  [ENV_OPTIONS.PRODUCTION]: 86400,
  [ENV_OPTIONS.DEVELOP]: 3600,
  [ENV_OPTIONS.TESTNET]: 3600,
}

const yieldGenerateTimestampOptions = {
  [ENV_OPTIONS.PRODUCTION]: 1713225681,
  [ENV_OPTIONS.DEVELOP]: 1713243716,
  [ENV_OPTIONS.TESTNET]: 1713243716,
}

const currentEnv = ENV_OPTIONS[process.env.REACT_APP_NODE_ENV];

export const backendApiUrl = backendApuUrlOptions[currentEnv];
export const rpc = rpcOptions[currentEnv];
export const rngHelperAddress = rngHelperAddressOptions[currentEnv];
export const wethAddress = wethAddressOptions[currentEnv];
export const builderAddress = builderAddressOptions[currentEnv];
export const rngAddress = rngAddressOptions[currentEnv];
export const hexChainId = hexChainIdOptions[currentEnv];
export const chainId = chainIdOptions[currentEnv];
export const chainName = chainNameOptions[currentEnv];
export const yieldGeneratePeriod = yieldGeneratePeriodOptions[currentEnv];
export const yieldGenerateTimestamp = yieldGenerateTimestampOptions[currentEnv];

export const provider = new ethers.BrowserProvider(window.ethereum, "any");

export const weth = new ethers.Contract(wethAddress, wethABI);

export const createSignature = async (signer, address) => {
  let message = await getVerifyMessage(address);
  const signature = await signer.signMessage(message);
  return signature;
};

export const calculateGasLimit = (originalGasLimit) => {
  return originalGasLimit * 15n / 10n
}

export const defaultProvider = new ethers.JsonRpcProvider(rpc, {
  name: chainName,
  chainId: chainId,
});

const projectId = 'dd11e7011e27d464fe707b82313e7879'

const blastSepolia = {
  chainId: 168587773,
  name: 'Blast Sepolia Testnet',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.blastscan.io/',
  rpcUrl: 'https://sepolia.blast.io/'
}

const blast = {
  chainId: 81457,
  name: 'Blast',
  currency: 'ETH',
  explorerUrl: 'https://blastscan.io/',
  rpcUrl: 'https://rpc.blast.io/'
}

const metadata = {
  name: 'Plur',
  description: 'Plur',
  url: window.location.origin, // origin must match your domain & subdomain
  icons: [`${window.location.origin}/favicon.ico`]
}

const chainConfig = {
  [ENV_OPTIONS.PRODUCTION]: blast,
  [ENV_OPTIONS.DEVELOP]: blastSepolia,
  [ENV_OPTIONS.TESTNET]: blastSepolia,
};

const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
})

createWeb3Modal({
  ethersConfig,
  chains: [chainConfig[currentEnv]],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
  ],
})
