import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myInfo: null,
  wallet: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateMyInfo: (state, action) => {
      let copiedData = {
        ...action.payload
      }
      const twitterProfile = copiedData?.user?.SocialProfiles.find(item => item.Type == "x" || item.Type == "bypass")
      const fcProfile = copiedData?.user?.SocialProfiles.find(item => item.Type == "warpcast")
      if (twitterProfile) {
        copiedData.user.twitterProfile = twitterProfile
      }
      if (fcProfile) {
        copiedData.user.fcProfile = fcProfile
      }

      state.myInfo = copiedData;
    },
    updateWallet: (state, action) => {
      state.wallet = action.payload;
    },
    updateSignature: (state, action) => {
      state.wallet = { ...state.wallet, signature: action.payload };
    },
  },
});

export const { updateMyInfo, updateWallet, updateSignature } =
  userSlice.actions;

export default userSlice.reducer;
