import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { CurrencyEth } from "@phosphor-icons/react";
import { ethers } from "ethers";

import prizePoolABI from "../../api/prizePool.json";
import ticketABI from "../../api/ticket.json";
import { defaultProvider } from "../../api/utils";
import { calculateGasLimit } from "../../api/utils";
import { startLoading, stopLoading } from "../../redux/modalSlice";
import { DEFAULT_PFP, delay } from "../../utils/utils";
import csvJson from "./csvjson0508.json";

const rowColumns = ["Pool Name", "Balance", ""];

function Withdraw() {
  const wallet = useSelector((state) => state.user.wallet);
  const [depositedRecords, setDepositedRecords] = useState([]);
  const [userLookupRecords, setUserLookupRecords] = useState();

  useEffect(() => {
    if (wallet && wallet.address) {
      let records = csvJson.filter(
        (record) => record.ethereum_addr === wallet.address,
      );
      setDepositedRecords(records);
      let userLookupHash = {};
      csvJson.map((record) => {
        userLookupHash[record.user_id] = record.user_name;
      });
      setUserLookupRecords(userLookupHash);
    }
  }, [wallet]);

  if (!wallet?.address) {
    return (
      <div className="flex h-full w-full items-center justify-center p-6 text-center font-inter font-semibold">
      Please connect your wallet to check your remaining balance
      </div>
    )
  }

  if (depositedRecords.length > 0) {
    return (
      <div className="w-full rounded-md p-6">
        <h1 className="pageTitle mb-6 text-indigo-950 before:mr-2 before:text-emerald-300 before:content-['✦'] after:ml-2 after:text-emerald-300 after:content-['✦'] md:text-center">
          Pools with Assets
        </h1>
        <div className="mb-3 flex w-full items-center justify-between gap-2 text-center text-sm text-gray-500 md:hidden">
          {rowColumns.map((column, index) => (
            <div key={index} className="w-1/3 ">
              {column}
            </div>
          ))}
        </div>
        <div className={"flex flex-col gap-2"}>
          {depositedRecords.map((record, index) => (
            <PoolRow
              key={index}
              record={record}
              userLookupRecords={userLookupRecords}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-center justify-center p-6 text-center font-inter font-semibold">
      You do not have unclaimed assets in pools
    </div>
  );
}

export default Withdraw;

function PoolRow({ record, userLookupRecords }) {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.user.wallet);
  const [ticketBalance, setTicketBalance] = useState();
  const [retryCount, setRetryCount] = useState(0);
  const fetchTicketBalance = async () => {
    let depositTokenAddress =
      record.sponsor_balance > 0 ? record.sponsorship_addr : record.ticket_addr;
    let token = new ethers.Contract(
      depositTokenAddress,
      ticketABI,
      defaultProvider,
    );
    try {
      let tokenBalance = await token.balanceOf(record.ethereum_addr);
      setTicketBalance(ethers.formatEther(tokenBalance));
      setRetryCount(0)
    } catch (e) {
      setRetryCount(prev => prev + 1)
    }
    
  };

  const handleWithdraw = async () => {
    try {
      dispatch(startLoading());
      let depositTokenAddress =
        record.sponsor_balance > 0
          ? record.sponsorship_addr
          : record.ticket_addr;
      let depositTokenAmount = ticketBalance;
      let prizePool = new ethers.Contract(
        record.pool_addr,
        prizePoolABI,
        wallet.signer,
      );
      const gasEstimated = await prizePool.withdrawInstantlyFrom.estimateGas(
        wallet.address,
        ethers.parseEther(depositTokenAmount),
        depositTokenAddress,
        0,
      );
      let withdrawTx = await prizePool.withdrawInstantlyFrom(
        wallet.address,
        ethers.parseEther(depositTokenAmount),
        depositTokenAddress,
        0,
        {
          gasLimit: calculateGasLimit(gasEstimated),
        },
      );
      let receipt = await defaultProvider.waitForTransaction(withdrawTx.hash);
      if (receipt?.status === 0) {
        throw new Error();
      }
      dispatch(stopLoading());
      toast.success("Withdrew successfully!");
    } catch (e) {
      dispatch(stopLoading());
      if (e.code === "ACTION_REJECTED") {
        toast.warn("Action rejected");
      } else {
        toast.error(
          <div>
            Withdraw failed
            <br />
            <span className="text-sm">Please try again later</span>
          </div>,
        );
      }
    }
    fetchTicketBalance();
  };

  useEffect(() => {
    fetchTicketBalance();
  }, []);

  useEffect(() => {
    const refetch = async (count) => {
      if (count !== 0) {
        await delay(3000)
        fetchTicketBalance()
      }
    }
    refetch(retryCount)
  }, [retryCount])

  return (
    <div
      className={`flex w-full items-center justify-between gap-2 rounded-md border border-solid border-gray-200 bg-gradient-to-l from-white to-white p-4 text-base md:flex-col`}
    >
      <div className="flex w-1/3 items-center overflow-x-hidden md:w-full md:justify-center">
        <img
          src={DEFAULT_PFP}
          alt={userLookupRecords[record.pool_owner_id]}
          className="mr-3 h-10 w-10 shrink-0 rounded-full md:h-8 md:w-8"
        />
        <div className="flex flex-col overflow-x-hidden">
          <span className="mr-1 flex items-center gap-1 truncate font-dec font-medium md:text-sm">
            <span className="truncate">
              {userLookupRecords[record.pool_owner_id] || "Anonymous User"}
            </span>
          </span>
        </div>
      </div>
      <span className="w-1/3 text-center font-dec font-medium md:w-full md:text-sm md:text-indigo-600">
        <span className="hidden font-sans md:block md:text-xs md:text-gray-500">
          {record.sponsor_balance > 0
            ? `Sponsored ${rowColumns[1]}`
            : `Staked ${rowColumns[1]}`}
        </span>
        <div className="inline-flex items-center">
          <CurrencyEth className="mr-1" size={16} weight="duotone" />
          {ticketBalance}
        </div>
      </span>
      <div className="flex w-1/3 justify-end md:w-full md:justify-center">
        <button
          className="w-40 rounded bg-emerald-400 px-4 py-2 text-sm font-semibold text-white enabled:hover:bg-emerald-500 disabled:cursor-not-allowed disabled:opacity-25 md:w-full md:py-1"
          onClick={handleWithdraw}
          disabled={+ticketBalance === 0}
        >
          Withdraw all
        </button>
      </div>
    </div>
  );
}
