import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

function LoadingModal() {
  const isModalOpen = useSelector((state) => state.modal.isLoading);
  if (isModalOpen) {
    return (
      <div style={{position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(128,128,128,0.3)', zIndex: 100}}>
        <ClipLoader color="#ffffff" size={30} />
      </div>
    ) 
  } else {
    return null
  }
}

export default LoadingModal