import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ArrowSquareOut, Bell, List, Wallet, X } from "@phosphor-icons/react";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useDisconnect,
} from "@web3modal/ethers/react";
import { ethers } from "ethers";

import {
  createSignature,
  chainName,
  weth,
  hexChainId,
  rpc,
} from "../../api/utils";
import useUpdateMyInfo from "../../hooks/useUpdateMyInfo";
import { ReactComponent as DcLogo } from "../../images/discord-logo.svg";
import { ReactComponent as PlurLogoIcon } from "../../images/plur-logo-icon.svg";
import { ReactComponent as PlurLogoText } from "../../images/plur-logo-text.svg";
import { ReactComponent as XLogo } from "../../images/x-logo-header.svg";
import { useGetContentsQuery } from "../../redux/hygraphApiSlice";
import { closeModal, openModal } from "../../redux/modalSlice";
import { closeNotif, openNotif } from "../../redux/paramsSlice";
import { updateWallet } from "../../redux/userSlice";
import contents from "../../utils/contents";
import {
  getSlicedAddress,
  setAmplitudeUserId,
  trackAmplitudeEvent,
  delay,
} from "../../utils/utils";
import BlastPoint from "../BlastPoint/BlastPoint";
import AccountModal from "../Modals/AccountModal/AccountModal";
import { refreshWhenAccountOrChainChanged } from "./helper";

function Header() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referral");
  const { data: contentsData } = useGetContentsQuery();
  const t = contentsData?.contents?.[0];

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.user.wallet);
  useUpdateMyInfo();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isNotifOpen = useSelector((state) => state.params.isNotifOpen);
  const hasNewNotif = useSelector((state) => state.params.hasNewNotif);
  const userId = useSelector((state) => state.user?.myInfo?.user?.ID);

  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (wallet?.address && address) {
      if (wallet.address !== address) {
        disconnect();
        refreshWhenAccountOrChainChanged(dispatch);
      }
    }
  }, [address, wallet]);

  useEffect(() => {
    if (userId) {
      setAmplitudeUserId(userId);
    }
  }, [userId]);

  useEffect(() => {
    const onWalletConnectSuccess = async () => {
      try {
        const provider = new ethers.BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        try {
          await walletProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: hexChainId }],
          });
        } catch (e) {
          console.log(e);
          await walletProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hexChainId,
                chainName: chainName,
                rpcUrls: [rpc] /* ... */,
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
              },
            ],
          });
          await delay(1000);
          await walletProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: hexChainId }],
          });
        }

        // const localSignature = localStorage.getItem("signatureV2");

        // let signature;
        // if (localSignature) {
        //   signature = localSignature;
        // } else {
        //   await delay(2000);
        //   signature = await createSignature(signer, signer.address);
        //   localStorage.setItem("signatureV2", signature);
        // }

        const balance = await provider.getBalance(signer.address);
        const wethBalance = await weth
          .connect(signer)
          .balanceOf(signer.address);
        dispatch(
          updateWallet({
            address: signer.address,
            balance: ethers.formatEther(balance),
            wethBalance: ethers.formatEther(wethBalance),
            signer,
            // signature,
          }),
        );
        // if (!localSignature && !window.location.href.includes("airdrop")) {
        //   dispatch(openModal(<AccountModal />));
        // }
      } catch (e) {
        console.log(e);
        trackAmplitudeEvent(
          `${contents.amp.catchError} | ${contents.amp.connectWallet}`,
          {
            message: e,
          },
        );
        toast.error(
          <div>
            Something went wrong when connecting wallet.
            <br />
            <span className="text-sm">
              Please switch to{" "}
              <span className="font-bold text-yellow-500">{chainName}</span> and
              try again later
            </span>
          </div>,
        );
        disconnect && disconnect();
      }
    };
    if (walletProvider) onWalletConnectSuccess();
  }, [walletProvider]);

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem("referral", referralCode);
    }
  }, [referralCode]);

  const isModalOpen = useSelector((state) => state.modal.isOpen);
  const modalContent = useSelector((state) => state.modal.content);
  const myInfo = useSelector((state) => state.user.myInfo);

  const onClickBell = () => {
    if (isNotifOpen) {
      dispatch(closeNotif());
    } else {
      dispatch(openNotif());
    }
  };

  return (
    <div
      className={`flex-0 relative z-20 border-y border-solid border-gray-100 ${isMenuOpen && "md:h-screen"}`}
    >
      <div
        className={`mx-auto flex h-16 max-w-[1600px] items-center justify-between gap-5 px-6 font-noto text-sm font-semibold text-indigo-600 md:px-3 ${isMenuOpen && "md:h-screenx"}`}
      >
        <Link
          to={"/"}
          className="relative mr-7 flex flex-nowrap items-center gap-2"
          onClick={() => setIsMenuOpen(false)}
        >
          <PlurLogoIcon className="h-9 w-auto" />
          <PlurLogoText className="h-6 w-auto" />
          <span className="absolute left-28 top-0 font-sans text-xs uppercase text-gray-600">
            {t?.header?.beta}
          </span>
        </Link>
        <div
          className={`mt-1 flex w-full items-center gap-4 ${isMenuOpen ? "md:absolute md:left-0 md:top-16 md:w-full md:flex-col md:px-3" : "md:hidden"}`}
        >
          <NavLink
            to={"/withdraw"}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `text-base hover:text-inherit ${isActive ? "opacity-100" : "opacity-60 hover:opacity-100"}`
            }
          >
            Withdraw
          </NavLink>
          {/* <NavLink
            to={"/pools"}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `text-base hover:text-inherit ${isActive ? "opacity-100" : "opacity-60 hover:opacity-100"}`
            }
          >
            {t?.header?.pools}
          </NavLink>
          <NavLink
            to={"/activities"}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `text-base hover:text-inherit ${isActive ? "opacity-100" : "opacity-60 hover:opacity-100"}`
            }
          >
            {t?.header?.activities}
          </NavLink>
          <NavLink
            to={"/points"}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `text-base hover:text-inherit ${isActive ? "opacity-100" : "opacity-60 hover:opacity-100"}`
            }
          >
            {t?.header?.points}
          </NavLink>
          <NavLink
            to={"/airdrop"}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `text-base hover:text-inherit ${isActive ? "opacity-100" : "opacity-60 hover:opacity-100"}`
            }
          >
            {t?.header?.airdrop}
          </NavLink> */}
          {/* <a
            href={t?.url?.plurGitBook}
            target="_blank"
            rel="noreferrer"
            className="flex w-fit items-center gap-1 text-base opacity-60 hover:opacity-100"
          >
            {t?.header?.docs}
            <ArrowSquareOut size={15} weight="bold" />
          </a>
          <div className="flex items-center gap-x-4 md:w-full md:justify-center md:border-b md:pb-4">
            <a
              href={t?.url?.plurXProfile}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsMenuOpen(false)}
            >
              <XLogo className="h-[14px] w-[14px] cursor-pointer fill-indigo-600 opacity-60 hover:opacity-100" />
            </a>
            <a
              href={t?.url?.plurDiscordInvitation}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsMenuOpen(false)}
            >
              <DcLogo className="h-5 w-5 cursor-pointer fill-indigo-600 opacity-60 hover:opacity-100" />
            </a>
          </div> */}
          <div className="ml-auto flex items-center justify-end gap-4 font-noto md:ml-0 md:w-full md:flex-col">
            {/* <BlastPoint /> */}
            {myInfo?.user?.SocialType && (
              <>
                <div className="relative md:hidden">
                  <Bell
                    className="h-5 w-5 cursor-pointer opacity-60 hover:opacity-100"
                    weight="fill"
                    onClick={onClickBell}
                  />
                  {hasNewNotif && (
                    <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-500" />
                  )}
                </div>
                <NavLink
                  to={`/profile/${myInfo?.user?.ID}`}
                  onClick={() => setIsMenuOpen(false)}
                  className={({ isActive }) =>
                    `flex items-center gap-2 text-base hover:text-inherit md:order-1 ${isActive ? "opacity-100" : "md:opacity-60 md:hover:opacity-100"}`
                  }
                >
                  <img
                    src={myInfo.user.Picture}
                    alt="user"
                    className="h-8 w-8 shrink-0 rounded-full xs:h-6 xs:w-6"
                  />
                  <span className="text-nowrap opacity-60 hover:opacity-100 lg:hidden md:block md:opacity-100">
                    {t?.header?.profile}
                  </span>
                </NavLink>
              </>
            )}
            {wallet ? (
              <>
                <p
                  className="font-sans text-xs opacity-60 hover:cursor-pointer hover:underline hover:opacity-100 lg:hidden md:order-1 md:block md:text-sm"
                  onClick={() => {
                    dispatch(openModal(<AccountModal />));
                    setIsMenuOpen(false);
                  }}
                >
                  {getSlicedAddress(wallet.address)}
                </p>
                <Wallet
                  weight="fill"
                  size={22}
                  className="mt-1 hidden cursor-pointer text-indigo-300 hover:text-indigo-600 lg:block lg:text-indigo-600 md:hidden"
                  onClick={() => {
                    dispatch(openModal(<AccountModal />));
                  }}
                />
              </>
            ) : (
              <button
                className="mt-1 text-base text-emerald-400 decoration-2 underline-offset-2 hover:underline md:order-1"
                onClick={() => {
                  open();
                }}
              >
                {t?.header?.connectWallet}
              </button>
            )}
          </div>
        </div>
        {myInfo?.user?.SocialType && (
          <div
            className={`relative ml-auto hidden ${isMenuOpen ? "md:hidden" : "md:block"}`}
          >
            <Bell
              className="h-5 w-5 cursor-pointer opacity-60 hover:opacity-100"
              weight="fill"
              onClick={onClickBell}
            />
            {hasNewNotif && (
              <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-500" />
            )}
          </div>
        )}
        <List
          size={24}
          className={`hidden cursor-pointer ${isMenuOpen ? "md:hidden" : "md:block"}`}
          onClick={() => setIsMenuOpen((prev) => !prev)}
        />
        <X
          size={24}
          className={`hidden cursor-pointer ${isMenuOpen ? "md:block" : "md:hidden"}`}
          onClick={() => setIsMenuOpen((prev) => !prev)}
        />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => dispatch(closeModal())}
          style={{
            overlay: { zIndex: 50 },
            content: modalWrapperStyle,
          }}
        >
          {modalContent}
        </Modal>
      </div>
    </div>
  );
}

export default Header;

const modalWrapperStyle = {
  height: "fit-content",
  width: "fit-content",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  padding: 0,
};
