import * as amplitude from "@amplitude/analytics-browser";
import { Decimal } from "decimal.js";

export const periodOption =
  process.env.REACT_APP_NODE_ENV === "PRODUCTION"
    ? [1, 3, 7, 14, 30].map((i) => ({
        value: 60 * 60 * 24 * i,
        label: `${i} day`,
      }))
    : new Array(7).fill().map((item, index) => ({
        value: (index + 1) * 60,
        label: `${index + 1} minute`,
      }));

export const drawNumberOption = new Array(10).fill().map((item, index) => ({
  value: index + 1,
  label: index + 1,
}));

export const getSlicedAddress = (address) =>
  address.length > 6
    ? `${address.slice(0, 6)}...${address.slice(
        address.length - 4,
        address.length,
      )}`
    : address;

export const formatIsoTimeStamp = (isoTimestamp) => {
  const date = new Date(isoTimestamp);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    // second: "numeric",
    // timeZoneName: "short",
  };

  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

export const getTrunc = (value) => {
  value = new Decimal(value);
  return value.toFixed(6, Decimal.ROUND_DOWN);
};

export const secondsToDHMS = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const formatSec = (timeInSeconds) => {
  const hour = Math.floor(timeInSeconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
  if ([hour, minutes, seconds].some((st) => st === "NaN")) return "--:--:--";
  return `${hour}:${minutes}:${seconds}`;
};

export const initAmplitude = () => {
  if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }
};

/**
 * Send a amplitude track event
 * @param {string} eventName e.g. 'Button Clicked'
 * @param {object} eventProps e.g. { buttonColor: 'primary' };
 */
export const trackAmplitudeEvent = (eventName, eventProps) => {
  if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
    amplitude.track(eventName, eventProps);
  }
};

export const setAmplitudeUserId = (userId) => {
  if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
    amplitude.setUserId(userId);
  }
};

export const imgurUrl = "https://api.imgur.com/3";
export const imgurClientId = process.env.REACT_APP_IMGUR_CLIENT_ID;

export const DEFAULT_PFP =
  "https://abs.twimg.com/sticky/default_profile_images/default_profile.png";

export const replaceProfilePic = ({ currentTarget }) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = DEFAULT_PFP;
};

export const DEFAULT_MIN_DEPOSIT_AMOUNT = "0.001";

export const overrideReactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#4F46E5", //focus:border, option bg
    primary75: "#4F46E5BF",
    primary50: "#4F46E580",
    primary25: "#4F46E540",
    // neutral90: "",
    neutral80: "#4F46E5", //text
    // neutral70: "",
    // neutral60: "",
    // neutral50: "",
    // neutral40: "",
    // neutral30: "",
    // neutral20: "", //border
    // neutral10: "",
    // neutral5: "",
    neutral0: "white", //bg
  },
});

export const delay = (delayInms) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
};
