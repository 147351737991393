import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { gql } from "graphql-request";

export const hygraphApiSlice = createApi({
  reducerPath: "hygraphApi",
  baseQuery: graphqlRequestBaseQuery({
    url: "https://api-ap-northeast-1.hygraph.com/v2/cltq204yl05lt07th8v24v3k9/master",
  }),
  keepUnusedDataFor: 3600, // 1 hour
  endpoints: (builder) => ({
    getCreatePoolModes: builder.query({
      query: () => ({
        document: gql`
          query ModesQuery {
            createPoolModes(orderBy: order_ASC) {
              desc
              isHighlight
              minDepositAmount
              subtitle
              title
              drawPeriodValue
              drawNumber
              isParamsChangeable
              splitFee
              missions {
                isMandatory
                title
              }
            }
          }
        `,
      }),
    }),
    getContents: builder.query({
      query: () => ({
        document: gql`
          query ContentsQuery {
            contents {
              header
              common
              url
              landingPage
              poolAndPoolsPage
              activitiesPage
              pointsPage
              airdropPage
              profilePage
              createPoolPage
              poolSettingModal
            }
          }
        `,
      }),
    }),
    getCreatePoolDefaultSettings: builder.query({
      query: () => ({
        document: gql`
          query DefaultSettingsQuery {
            defaultCreatePoolSettings {
              drawNumber
              drawPeriodValue
              minDepositAmount
              splitFee
              missions {
                isMandatory
                title
              }
            }
          }
        `,
      }),
    }),
  }),
});

export const {
  useGetCreatePoolModesQuery,
  useGetContentsQuery,
  useGetCreatePoolDefaultSettingsQuery,
} = hygraphApiSlice;
