import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Chats,
  HeartStraight,
  Newspaper,
  PiggyBank,
} from "@phosphor-icons/react";
import { ethers } from "ethers";
import moment from "moment";

import { closeNotif } from "../../redux/paramsSlice";
import { DEFAULT_PFP, formatIsoTimeStamp } from "../../utils/utils";
import { truncateContents } from "./helper";

const notificationIcons = {
  liked_post: (
    <HeartStraight
      className="absolute -right-2 bottom-0 h-4 w-4 rounded-full bg-red-100 p-0.5 text-red-400"
      weight="fill"
    />
  ),
  root_post: (
    <Newspaper
      className="absolute -right-2 bottom-0 h-4 w-4 rounded-full bg-indigo-100 p-0.5 text-indigo-500"
      weight="fill"
    />
  ),
  replied_post: (
    <Chats
      className="absolute -right-2 bottom-0 h-4 w-4 rounded-full bg-emerald-100 p-0.5 text-emerald-500"
      weight="fill"
    />
  ),
  deposit_pool: (
    <PiggyBank
      className="absolute -right-2 bottom-0 h-4 w-4 rounded-full bg-yellow-100 p-0.5 text-yellow-500"
      weight="fill"
    />
  ),
};

function NotifRow({ notif }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const renderNotifText = (notif) => {
    switch (notif.type) {
      case "liked_post":
        if (notif.numbers > "2") {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name}</span> and{" "}
              {notif.numbers - 1} others liked your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.contents)}"
              </span>
            </div>
          );
        } else if (notif.numbers === "2") {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name}</span> and{" "}
              <span className="font-semibold">{notif.users[1].name}</span> liked
              your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.contents)}"
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name} </span>
              liked your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.contents)}"
              </span>
            </div>
          );
        }
      case "root_post":
        return (
          <div>
            <span className="font-semibold">{notif.users[0].name}</span> posted
            in the pool you subscribed:{" "}
            <span className="font-semibold">
              "{truncateContents(notif.contents)}"
            </span>
          </div>
        );
      case "replied_post":
        if (notif.numbers > "2") {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name}</span> and{" "}
              {notif.numbers - 1} others replied to your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.parentContents)}"
              </span>
            </div>
          );
        } else if (notif.numbers === "2") {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name}</span> and{" "}
              <span className="font-semibold">{notif.users[1].name}</span>{" "}
              replied to your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.parentContents)}"
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="font-semibold">{notif.users[0].name} </span>
              replied{" "}
              <span className="font-semibold">
                "{truncateContents(notif.contents)}"
              </span>{" "}
              to your post/comment{" "}
              <span className="font-semibold">
                "{truncateContents(notif.parentContents)}"
              </span>
            </div>
          );
        }
      case "deposit_pool":
        return (
          <div>
            <span className="font-semibold">{notif.users[0].name}</span>{" "}
            deposited{" "}
            <span className="font-semibold">
              {ethers.formatEther(`${notif.numbers}`)} ETH
            </span>{" "}
            into your pool.
          </div>
        );
      default:
        return "nothing to tell";
    }
  };

  return (
    <div
      className={`flex cursor-pointer items-center justify-between rounded p-2  ${notif.viewed === false ? "bg-indigo-50/50 hover:bg-indigo-50" : "bg-white hover:bg-gray-100"}`}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(closeNotif());
        navigate(`/pool?pool-id=${notif.poolId}`);
      }}
    >
      <div className="flex items-center gap-4">
        <div className="relative shrink-0 ">
          <img
            src={notif.users[0].Picture || DEFAULT_PFP}
            alt=""
            className="h-8 w-8 rounded-full bg-indigo-950 object-cover"
          />
          {notificationIcons[notif.type]}
        </div>
        <div className="">
          <div className="font-inter text-sm font-normal text-gray-950">
            {renderNotifText(notif)}
          </div>
          <p
            className="font-inter text-xs font-normal text-gray-500"
            title={formatIsoTimeStamp(notif.createdAt)}
          >
            {moment(notif.createdAt).fromNow() || ""}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotifRow;
