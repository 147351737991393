export const PLUR_TESTNET_URL = "https://testnet.plur.xyz/";

// Social links of Plur
export const PLUR_X_URL = "https://x.com/plurxyz";
export const PLUR_DISCORD_URL = "https://discord.com/invite/wjxfxkvQK5";

// Git book
export const GIT_BOOK_URL = "https://plurxyz.gitbook.io/plur";
export const UNIVERSAL_POOL_PATHNAME = "/universal-pool";
export const POINT_SYSTEM_PATHNAME = "/plur-point-system";
export const BLAST_POINT_PATHNAME = "/blast-point-and-gold";
export const AIRDROP_I_PATHNAME = "/plur-point-airdrop-i";

// Links to social profiles (with handle at the end)
export const X_PROFILE_URL = "https://twitter.com";
export const WARPCAST_PROFILE_URL = "https://warpcast.com";
