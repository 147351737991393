import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMyInfo } from "../api/API";
import { updateMyInfo } from "../redux/userSlice";
import contents from "../utils/contents";
import { trackAmplitudeEvent } from "../utils/utils";

const useUpdateMyInfo = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.user.wallet);
  const [isMyInfoLoading, setIsMyInfoLoading] = useState(false);

  const setMyInfo = async () => {
    try {
      if (!wallet) {
        return;
      }
      setIsMyInfoLoading(true);
      let myInfoData = await getMyInfo(wallet);
      if (myInfoData) {
        dispatch(updateMyInfo(myInfoData));
        return myInfoData;
      }
    } catch (error) {
      trackAmplitudeEvent(`${contents.amp.catchError} | getMyInfo`, {
        message: error,
      });
      dispatch(updateMyInfo(null));
    } finally {
      setIsMyInfoLoading(false);
    }
  };

  useEffect(() => {
    setMyInfo();
  }, [dispatch, wallet]);

  return { setMyInfo, isMyInfoLoading };
};

export default useUpdateMyInfo;
