import React from "react";

import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";

function Paging({ currentPage, handleClick, totalPages }) {
  if (!totalPages || totalPages === 1) return;
  return (
    <div className="my-3 flex flex-wrap items-center justify-center gap-2 text-indigo-700">
      <CaretDoubleLeft
        className={`${currentPage <= 1 ? "cursor-default opacity-0" : "cursor-pointer hover:bg-indigo-400 hover:text-white"} h-5 w-5 rounded-full p-1`}
        onClick={() => {
          if (currentPage <= 1) return;
          handleClick(1);
        }}
      />
      <CaretLeft
        className={`${currentPage <= 1 ? "cursor-default opacity-0" : "cursor-pointer hover:bg-indigo-400 hover:text-white"} h-5 w-5 rounded-full p-1`}
        onClick={() => {
          if (currentPage <= 1) return;
          handleClick(currentPage - 1);
        }}
      />
      {Array(totalPages)
        .fill()
        .map((_, i) => (
          <div
            key={i + 1}
            className={`${currentPage === i + 1 && "bg-indigo-700 text-white"} flex h-5 w-5 cursor-pointer items-center justify-center rounded-full text-sm decoration-2 underline-offset-4 transition-all hover:bg-indigo-400 hover:text-white`}
            onClick={() => handleClick(i + 1)}
          >
            {i + 1}
          </div>
        ))}
      <CaretRight
        className={`${currentPage >= totalPages ? "cursor-default opacity-0" : "cursor-pointer hover:bg-indigo-400 hover:text-white"} h-5 w-5 rounded-full p-1`}
        onClick={() => {
          if (currentPage >= totalPages) return;
          handleClick(currentPage + 1);
        }}
      />
      <CaretDoubleRight
        className={`${currentPage >= totalPages ? "cursor-default opacity-0" : "cursor-pointer hover:bg-indigo-400 hover:text-white"} h-5 w-5 rounded-full p-1`}
        onClick={() => {
          if (currentPage >= totalPages) return;
          handleClick(totalPages);
        }}
      />
    </div>
  );
}

export default Paging;
