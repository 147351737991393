const amplitude = {
  // account
  connectWallet: "Connect Wallet",
  disconnectWallet: "Disconnect Wallet",
  connectX: "Connect X Account",
  connectFarcaster: "Connect Farcaster Account",

  // pools
  clickTopCreatorPool: "Click Top Creator's Pool",
  clickPool: "Click Pool",

  // pool
  clickStakeButton: "Click Stake Button",
  clickWithdrawButton: "Click Withdraw Button",
  clickWrapButton: "Click Wrap Button",
  approveToken: "Approve Token",
  stakeToken: "StakeToken",
  withdrawToken: "WithdrawToken",
  wrapToken: "WrapToken",
  clickDrawButton: "Click Draw Button",
  post: "Post",
  comment: "Comment",
  reply: "Reply",
  clickCreatePoolButton: "Click Create Pool Button",

  //others
  catchError: "Catch Error",
  appCrash: "App Crash",
};

const contents = {
  amp: amplitude,
};

export default contents;
