import { toast } from "react-toastify";

import { provider, weth, rpc, chainName, hexChainId } from "../../api/utils";
import { updateMyInfo, updateWallet } from "../../redux/userSlice";
import contents from "../../utils/contents";
import { trackAmplitudeEvent } from "../../utils/utils";

export const connectWallet = async (onSuccess, onError) => {
  try {
    const signer = await provider.getSigner();
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChainId }],
      });
    } catch (switchError) {
      // This error code indicates that user rejected the request.
      if (switchError.code === 4001) {
        toast.warn("Action rejected");
      }
      // This error code indicates that the chain has not been added to MetaMask.
      else if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hexChainId,
                chainName: chainName,
                rpcUrls: [rpc] /* ... */,
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18
                },
              },
            ],
          });
        } catch (addError) {
          toast.error(
            <div>
              {`Something went wrong when adding the ${chainName} chain.`}
              <br />
              <span className="text-sm">Please try again later</span>
            </div>,
          );
          trackAmplitudeEvent(`${contents.amp.catchError} | addChain`, {
            message: addError,
          });
        }
      }
      // handle other "switch" errors
      else {
        toast.error(
          <div>
            {`Something went wrong when switching to the ${chainName} chain.`}
            <br />
            <span className="text-sm">Please try again later</span>
          </div>,
        );
        trackAmplitudeEvent(`${contents.amp.catchError} | switchChain`, {
          message: switchError,
        });
      }
    }
    const balance = await provider.getBalance(signer.address);
    const wethBalance = await weth.connect(signer).balanceOf(signer.address);
    onSuccess(signer, balance, wethBalance);
  } catch (e) {
    onError(e);
  }
};

export const refreshWhenAccountOrChainChanged = (dispatch) => {
  dispatch(updateWallet(null));
  dispatch(updateMyInfo(null));
  localStorage.removeItem("wallet");
  localStorage.removeItem("signatureV2");
  window.location.reload();
};
