import { configureStore } from "@reduxjs/toolkit";

import { externalApiSlice } from "./externalApiSlice";
import { hygraphApiSlice } from "./hygraphApiSlice";
import modalReducer from "./modalSlice";
import paramsReducer from "./paramsSlice";
import poolsApi from "./poolsApi";
import userReducer from "./userSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    params: paramsReducer,
    [poolsApi.reducerPath]: poolsApi.reducer,
    [externalApiSlice.reducerPath]: externalApiSlice.reducer,
    [hygraphApiSlice.reducerPath]: hygraphApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(poolsApi.middleware)
      .concat(externalApiSlice.middleware)
      .concat(hygraphApiSlice.middleware),
  devTools: !(process.env.REACT_APP_NODE_ENV === "PRODUCTION"),
});
