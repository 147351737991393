import { Link, useRouteError } from "react-router-dom";

import { SmileyXEyes } from "@phosphor-icons/react";

import contents from "../../utils/contents";
import { PLUR_DISCORD_URL } from "../../utils/urls";
import { trackAmplitudeEvent } from "../../utils/utils";

function ErrorBoundary() {
  let error = useRouteError();
  trackAmplitudeEvent(`${contents.amp.appCrash}`, {
    message: error,
  });
  return (
    <div className="flex h-dvh w-dvw flex-col items-center justify-center gap-4 bg-gradient-to-b from-white to-indigo-200 p-6 font-dec text-lg">
      <div className="logo">Plur</div>
      <div className="flex items-center justify-center gap-1 text-xl font-semibold">
        Oops, something went wrong with the app!{" "}
        <SmileyXEyes weight="bold" size={30} />
      </div>
      <div>
        Back to{" "}
        <Link
          to="/pools"
          className="text-indigo-600 hover:text-indigo-800 hover:underline"
        >
          homepage
        </Link>
      </div>
      <div className="-mt-2 text-sm">or</div>
      <div className="-mt-2 flex items-center justify-center gap-1">
        Contact the developers via{" "}
        <a
          href={PLUR_DISCORD_URL}
          className="text-indigo-600 hover:text-indigo-800 hover:underline"
        >
          Plur's Official Discord
        </a>
      </div>
      {error.message && (
        <div className="-mt-3 font-sans text-xs text-gray-500">{`<Error Message: ${error.message}>`}</div>
      )}
    </div>
  );
}
export default ErrorBoundary;
