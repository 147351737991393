import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { initAmplitude } from "./utils/utils";

// fix redux devTool error "Do not know how to serialize a BigInt"
// eslint-disable-next-line no-undef
BigInt.prototype.toJSON = function () {
  return this.toString();
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initAmplitude();
