import { createSlice } from "@reduxjs/toolkit";

import { SORT_BY_OPTIONS, SORT_IN_OPTIONS } from "../pages/Pools/utils";

const initialState = {
  pools: {
    sortBy: SORT_BY_OPTIONS[0].value,
    sortIn: SORT_IN_OPTIONS[0],
    page: 1,
  },
  isNotifOpen: false,
  hasNewNotif: false,
  isMainnetBannerShown: true,
};

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    updatePoolsSortBy: (state, action) => {
      state.pools.sortBy = action.payload;
    },
    updatePoolsSortIn: (state, action) => {
      state.pools.sortIn = action.payload;
    },
    updatePoolsPage: (state, action) => {
      state.pools.page = action.payload;
    },
    openNotif: (state) => {
      state.isNotifOpen = true;
    },
    closeNotif: (state) => {
      state.isNotifOpen = false;
    },
    receiveNewNotif: (state) => {
      state.hasNewNotif = true;
    },
    readNotif: (state) => {
      state.hasNewNotif = false;
    },
    closeMainnetBanner: (state) => {
      state.isMainnetBannerShown = false;
    },
  },
});

export const {
  updatePoolsSortBy,
  updatePoolsSortIn,
  updatePoolsPage,
  openNotif,
  closeNotif,
  receiveNewNotif,
  readNotif,
  closeMainnetBanner,
} = paramsSlice.actions;

export default paramsSlice.reducer;
